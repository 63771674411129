<template>
    <v-card
        fluid
        fill-height
        height="100vh"
        id="principal"
        data-cy="signinCard"
        class="principal-bg-login"
        :style="{
            height: '100%',
            width: '100%',
            backgroundImage: backgroundImage,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover'
        }"
    >
        <v-col
            :cols="socialUsers.length ? 12 : 10"
            :sm="socialUsers.length ? 12 : 10"
            md="10"
            :lg="socialUsers.length ? 6 : 4"
            class="ma-auto"
        >
            <v-card class="mt-10" color="rgb(255, 255, 255, 0.95)">
                <v-col cols="8" sm="4" md="4" lg="8" class="ma-auto pt-10">
                    <v-img
                        align-center
                        justify-center
                        data-cy="signinLogo"
                        :src="logo"
                        alt="logo-recuperi"
                        class=""
                    />
                </v-col>

                <v-col v-if="!social_user" cols="12" lg="10" class="ma-auto">
                    <v-card-text>
                        <v-form
                            ref="form"
                            v-model="valid"
                            @keyup.native.enter="checkformSubmit()"
                        >
                            <v-text-field
                                prepend-inner-icon="person"
                                name="email"
                                label="Digite seu login de usuário"
                                type="email"
                                v-model="email"
                                :rules="emailRules"
                                required
                                small
                                solo
                                data-cy="signinEmailField"
                            ></v-text-field>
                            <v-text-field
                                prepend-inner-icon="lock"
                                name="password"
                                label="Digite sua senha"
                                :type="show1 ? 'text' : 'password'"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="show1 = !show1"
                                data-cy="signinPasswordField"
                                v-model="password"
                                :rules="passwordRules"
                                small
                                solo
                                required
                            ></v-text-field>
                            <v-btn
                                text
                                x-small
                                dense
                                @click="dialogRecuperar = !dialogRecuperar"
                                class="grey--text mt-4"
                                >Esqueceu a senha?</v-btn
                            >
                            <v-checkbox
                                v-model="checkboxTermos"
                                data-cy="signinTermosCheckbox"
                                x-small
                                required
                            >
                                <template v-slot:label>
                                    <div>
                                        Declaro estar de acordo com os
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <a
                                                    target="_blank"
                                                    href="https://recuperi.com.br/termos-de-uso"
                                                    @click.stop
                                                    v-on="on"
                                                >
                                                    Termos de Uso
                                                </a>
                                            </template>
                                            Termos de uso
                                        </v-tooltip>
                                        ,
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <a
                                                    target="_blank"
                                                    href="https://recuperi.com.br/politica-de-privacidade"
                                                    @click.stop
                                                    v-on="on"
                                                >
                                                    Política de Privacidade
                                                </a>
                                            </template>
                                            Política de Privacidade
                                        </v-tooltip>
                                        e
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <a
                                                    target="_blank"
                                                    href="https://recuperi.com.br/codigo-de-etica"
                                                    @click.stop
                                                    v-on="on"
                                                >
                                                    Código de Ética
                                                </a>
                                            </template>
                                            Código de Ética
                                        </v-tooltip>
                                    </div>
                                </template>
                            </v-checkbox>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            color="primary"
                            :disabled="checkForm()"
                            @click="submit"
                            block
                            data-cy="signinSubmitBtn"
                            :loading="load_login"
                        >
                            Entrar
                            <v-icon class="ml-1">chevron_right</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-col>

                <v-col
                    v-if="social_user"
                    cols="12"
                    lg="10"
                    class="social-user-select ma-auto"
                >
                    <v-card-text>
                        <strong>Selecione o acesso para prosseguir:</strong>

                        <SocialUserAlterarUsuario />
                    </v-card-text>
                </v-col>
            </v-card>
        </v-col>
        <v-dialog v-model="dialogRecuperar" max-width="400">
            <v-card>
                <v-card-text
                    v-if="this.email == '' || !/.+@.+/.test(this.email)"
                    class="pt-5 mb-n10"
                    >Informe um e-mail válido</v-card-text
                >
                <v-card-text
                    v-else
                    class="px-4 pt-4 pb-0"
                    style="text-align: justify"
                    >Vamos verificar o login <b>{{ email }}</b> no sistema e, se
                    pertencer a um usuário válido, enviaremos um e-mail para o
                    endereço vinculado a ele.</v-card-text
                >

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        v-if="this.email != '' && /.+@.+/.test(this.email)"
                        color="green darken-1"
                        text
                        :loading="loadingEnviaEmail"
                        @click="enviaEmail(email)"
                        >Confirmar</v-btn
                    >
                    <v-btn
                        color="red"
                        text
                        @click="dialogRecuperar = !dialogRecuperar"
                        >Cancelar</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog max-width="600px" v-model="dialogTokenExpirou">
            <v-card class="text-center">
                <div>
                    <v-card-text class="title"
                        ><v-icon class="ma-1">mdi-alert-circle-outline</v-icon
                        >Sua sessão expirou devido a inatividade
                    </v-card-text>
                    <v-card-text
                        >Faça login novamente para ter acesso ao
                        painel</v-card-text
                    >
                    <v-btn
                        @click="desabilitaDialogToken"
                        class="ma-3 pa-3 primary white--text"
                        ><v-icon class="ma-1">mdi-chevron-right</v-icon> refazer
                        login</v-btn
                    >
                    <v-col lg="10" class="ma-auto"
                        ><v-card-text class="font-weight-light body-2"
                            >Por medidas de segurança, todo acesso ao painel
                            possui limite de tempo de inatividade, essas medidas
                            são necessárias para garantir a segurança e o bom
                            funcionamento do sistema dentro das normas mundiais
                            de segurança</v-card-text
                        ></v-col
                    >
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogSenhaExpirou" max-width="550px">
            <v-card class="pa-3">
                <v-card-text
                    class="
                        pt-5
                        primary-text
                        text-center
                        display-1
                        font-weight-bold
                    "
                    >Ops. Sua Senha expirou :(</v-card-text
                >
                <v-row class="ma-auto text-center justify-center">
                    <v-col sm="10" lg="10">
                        <v-card-text class="text-justify">
                            Você receberá um <b>e-mail</b> com o link de
                            redefinição de senha para criar um novo acesso.
                            Essas medidas de segurança são necessárias para
                            manter sua senha sempre atualizada. Na Recuperi, a
                            segurança dos dados vem sempre em primeiro lugar.
                            <br /><br />
                            {{
                                emailSenhaExpirada
                                    ? `O e-mail será enviado para ${emailSenhaExpirada}`
                                    : 'O e-mail será enviado para o endereço fornecido em seu cadastro.'
                            }}
                        </v-card-text>
                    </v-col>
                </v-row>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        v-if="this.email != '' && /.+@.+/.test(this.email)"
                        color="green darken-1"
                        text
                        :loading="loadingEnviaEmail"
                        @click="enviaEmail(email)"
                        >Confirmar</v-btn
                    >
                    <v-btn color="red" text @click="dialogSenhaExpirou = false"
                        >Cancelar</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogPrimeiroAcesso" max-width="800px">
            <v-card class="pa-3">
                <v-card-text
                    class="
                    pt-5
                    primary-text
                    text-center
                    display-1
                    font-weight-bold
                "
                >
                    Esse é seu primeiro acesso, Vamos começar:
                </v-card-text>
                <v-row class="ma-auto text-center justify-center">
                    <v-col sm="10" lg="10">
                        <v-card-text class="text-center">
                            <v-icon class="ma-3">mdi-email</v-icon>
                            Um e-mail será enviado para o endereço cadastrado
                            com instruções para a criação de uma nova senha.
                        </v-card-text>
                        <v-card-text class="text-center">
                            Você receberá um link de redefinição de senha no seu
                            e-mail cadastrado. Nosso sistema fornece uma senha
                            inicial que precisa ser ajustada no primeiro acesso.
                            Essas medidas de segurança são necessárias para
                            manter sempre sua senha atualizada. Na Recuperi, a
                            segurança dos dados vem sempre em primeiro lugar.
                        </v-card-text>
                    </v-col>
                </v-row>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1 white--text"
                        @click="enviaEmail()"
                    >
                        Confirmar
                    </v-btn>
                    <v-btn
                        color="red"
                        text
                        @click="dialogPrimeiroAcesso = false"
                    >
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <Snackbar
            v-if="snackbar"
            :mostrarSnackbar="snackbar"
            :corSnackbar="colorSnackbar"
            :mensagemSnackbar="mensagemSnackbar"
            @fecharSnackbar="snackbar = false"
        />
    </v-card>
</template>

<script>
import {
    validarStringSemEspaco,
    getMensagemRequest
} from '@/helpers/Utilitarios';
import router from '@/router';
import esquecisenhaService from '@/services/esquecisenhaService';
import Vue from 'vue';
import store from '@/store/index';
import moment from 'moment';

import SocialUserAlterarUsuario from '../components/SocialUserAlterarUsuario.vue';

export default {
    name: 'Login',
    components: {
        SocialUserAlterarUsuario
    },
    data() {
        return {
            lastUrl: null,
            dialogPrimeiroAcesso: false,
            loadingEnviaEmail: false,
            dialogTokenExpirou: false,
            snackbarSenhaExpirada: null,
            mensagemSnackbarSenhaExpirada: null,
            backgroundImage:
                'url(' + require('@/assets/bg-home-up-recuperi.png') + ')',
            emailUsuario: null,
            dialogSenhaExpirou: null,
            emailSenhaExpirada: null,
            mensagemSnackbar: null,
            colorSnackbar: null,
            show1: false,
            logo: require('@/assets/logo_recuperi_azul_escura.png'),
            valid: false,
            email: '',
            subDomain: '',
            forceWhiteLabel: false,
            ds_identidade_visual: null,
            load_login: false,
            checkboxTermos: false,
            password: '',
            dialogRecuperar: false,
            snackbar: false,
            emailRules: [
                v => !!v || 'Login de usuário é requerido',
                v => /.+@.+/.test(v) || 'Login de usuário inválido',
                v =>
                    this.validarStringSemEspaco(v) ||
                    'Não é permitido utilizar espaços.'
            ],
            passwordRules: [
                v => !!v || 'Senha é requerida',
                v => v.length >= 2 || 'A senha deve ter 2 ou mais caracteres',
                v =>
                    this.validarStringSemEspaco(v) ||
                    'Não é permitido utilizar espaços.'
            ],
            social_user: false
        };
    },
    computed: {
        itensMunicipios() {
            return this.$store.getters.itensMunicipios;
        },
        socialUsers() {
            try {
                return this.$store.state.user.users;
            } catch (e) {
                return [];
            }
        }
    },
    mounted() {
        this.subDomain = window.location.hostname.split('.')[0];
        this.forceWhiteLabel = window.location.href.includes('white-label');
        this.validOrigin();
        if (store.getters.tokenExpired === true) {
            this.dialogTokenExpirou = true;
        }
        this.lastUrl = sessionStorage.getItem('last-url');
    },
    methods: {
        validarStringSemEspaco,
        getMensagemRequest,
        validOrigin() {
            this.ds_identidade_visual = localStorage.getItem(
                'ds_identidade_visual'
            );

            this.ds_identidade_visual =
                typeof this.ds_identidade_visual != 'undefined' &&
                this.ds_identidade_visual != 'undefined' &&
                this.ds_identidade_visual
                    ? JSON.parse(this.ds_identidade_visual.replace(/'/g, '"'))
                    : null;

            if (
                !['localhost', 'painel-hom', 'painel'].includes(
                    this.subDomain
                ) &&
                !this.ds_identidade_visual
            ) {
                this.logo = null;
                this.backgroundImage =
                    'linear-gradient(to bottom, #cccccc, #999999)';
            } else if (
                (!['localhost', 'painel-hom', 'painel'].includes(
                    this.subDomain
                ) ||
                    this.forceWhiteLabel) &&
                this.ds_identidade_visual
            ) {
                this.logo = this.ds_identidade_visual.logo_completo;
                this.backgroundImage =
                    'url(' + this.ds_identidade_visual.bg_login + ')';
                this.$vuetify.theme.themes.light.primary = this.ds_identidade_visual.primary;
                this.$vuetify.theme.themes.light.secondary = this.ds_identidade_visual.secondary;
            }
        },
        desabilitaDialogToken() {
            this.dialogTokenExpirou = false;
        },
        verificarDataItensMunicipios() {
            const requestDate = this.itensMunicipios.requestDate;
            const nowDate = moment();
            const isExpired = nowDate
                .subtract(1, 'hours')
                .isSameOrAfter(requestDate);
            if (isExpired) {
                this.$store.commit('setItensMunicipios', {});
            }
        },
        submit() {
            this.snackbar = false;
            this.load_login = true;
            if (this.$refs.form.validate()) {
                this.$store
                    .dispatch('userLogin', {
                        email: this.email,
                        password: this.password
                    })
                    .then(
                        response => {
                            if (
                                typeof response.data.body.user_social !=
                                    'undefined' &&
                                response.data.body.user_social
                            ) {
                                this.social_user = true;
                                return false;
                            }

                            if (!this.itensMunicipios.requestDate) {
                                this.$store.commit('setItensMunicipios', {});
                            } else {
                                this.verificarDataItensMunicipios();
                            }
                            if (this.$store.getters.isLite == 1) {
                                router.push('/consulta-titulos');
                            } else {
                                let lastUrl =
                                    !this.lastUrl ||
                                    this.lastUrl.indexOf('login') !== -1
                                        ? '/'
                                        : this.lastUrl;
                                router.push(lastUrl);
                            }
                            // this.$store.dispatch('resetState');
                            // window.location.reload();
                        },
                        error => {
                            console.log(error);
                            if (
                                error.body.mensagem ==
                                    'Usuário ou senha inválidos' ||
                                error.body.mensagem ==
                                    'Usuário ou senha incorretos.'
                            ) {
                                this.snackbar = true;
                                (this.colorSnackbar = 'red'),
                                    (this.mensagemSnackbar =
                                        'Usuário ou Senha Incorreta, verifique os dados e tente novamente');
                            }
                            if (
                                error.body.mensagem ==
                                'Prazo para renovação de senha expirado'
                            ) {
                                this.snackbar = true;
                                this.colorSnackbar = 'red';
                                this.mensagemSnackbar =
                                    'Sua Senha expirou! Crie uma nova senha para realizar o acesso';
                                this.dialogSenhaExpirou = true;
                                this.emailSenhaExpirada =
                                    error.body.email ?? null;
                            }
                            if (
                                error.body.mensagem ===
                                'Múltiplas tentativas de autenticação inválidas'
                            ) {
                                this.snackbar = true;
                                this.colorSnackbar = 'red';
                                this.mensagemSnackbar =
                                    'Múltiplas tentativas de autenticação inválidas! Crie uma nova senha para realizar o acesso';
                                this.dialogSenhaExpirou = true;
                            }
                            if (
                                error.body.mensagem ==
                                'Esse é seu primeiro acesso, crie uma nova senha'
                            ) {
                                this.snackbar = true;
                                this.colorSnackbar = 'red';
                                this.mensagemSnackbar =
                                    'Esse é seu primeiro acesso, crie uma nova senha!';
                                this.dialogPrimeiroAcesso = true;
                            }
                            this.load_login = false;
                        }
                    );
            }
        },
        enviaEmail() {
            this.loadingEnviaEmail = true;
            return new Promise((resolve, reject) => {
                try {
                    this.dialogRecuperar = false;
                    let service = new esquecisenhaService(
                        Vue.http,
                        this.$store
                    );
                    service
                        .envia_email(this.email, window.location.origin)
                        .then(
                            response => {
                                this.dialogSenhaExpirou = false;
                                this.dialogPrimeiroAcesso = false;
                                this.snackbar = true;
                                this.mensagemSnackbar = this.getMensagemRequest(
                                    response.body,
                                    'Solicitação enviada com sucesso! Verifique seu e-mail para acessar o painel'
                                );
                                this.colorSnackbar = 'green';
                                this.loadingEnviaEmail = false;
                            },
                            error => {
                                console.log(error);
                                this.loadingEnviaEmail = false;
                            }
                        );
                } catch (e) {
                    console.log(e);
                    reject();
                }
            });
        },
        checkForm() {
            if (this.valid && this.checkboxTermos) {
                return false;
            }
            return true;
        },
        checkformSubmit() {
            const check = this.checkForm();
            if (!check) {
                this.submit();
            }
        }
    }
};
</script>

<style lang="scss">
.principal-bg-login {
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.social-user-select {
    strong {
        width: 100%;
        text-align: center;
        display: block;
        margin-bottom: 10px;
    }
}
</style>
